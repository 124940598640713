import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Utterances from "../../components/utterances"

const PostLayout = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  const utterancesRepo = data.site.siteMetadata.utterancesRepo

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </header>
        <MDXRenderer>{post.body}</MDXRenderer>
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={"/posts/" + previous.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={"/posts/" + next.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>

      <Utterances repo={utterancesRepo} />
    </Layout>
  )
}

export default PostLayout

export const query = graphql`
  query BlogPostById(
    $id: String! # $previousPostId: String # $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        utterancesRepo
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    # previous: mdx(id: { eq: $previousPostId }) {
    #   slug
    #   frontmatter {
    #     title
    #   }
    # }
    # next: mdx(id: { eq: $nextPostId }) {
    #   slug
    #   frontmatter {
    #     title
    #   }
    # }
  }
`
